import { useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Button,
    Badge,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import {
    FiMenu,
    FiChevronDown,
} from 'react-icons/fi';
import {
    AiFillDollarCircle,
    AiFillHome,
    AiOutlineLink,
    AiOutlineShoppingCart,
    AiOutlineTransaction,
    AiOutlineUser,
} from 'react-icons/ai';
import Logo from './Logo';
import Alert from './Alert';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePageContext } from '../context/pageContext';

const LinkItems = [
    { name: 'Dashboard', icon: AiFillHome, link: '/home' },
    { name: 'Manage Links', icon: AiOutlineLink, link: '/links' },
    { name: 'Shop Settings', icon: AiOutlineShoppingCart, link: '/edit_profile' },
    { name: 'Subscription', icon: AiFillDollarCircle, link: '/subscription' },
    {
        name: 'Influencers',
        icon: AiOutlineUser,
        link: '/admin/users',
        adminOnly: true,
    },
    {
        name: 'Transactions',
        icon: AiOutlineTransaction,
        link: '/admin/transaction',
        adminOnly: true,
    },
];

export default function Sidebar({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();
    useEffect(() => {
        onClose();
    }, [location.pathname, onClose]);
    return (
        <Box minH="100vh" bg={useColorModeValue('white')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="48px">
                {children}
            </Box>
        </Box>
    );
}

const SidebarContent = ({ onClose, ...rest }) => {
    const selector = useSelector(state => state.Login.details);
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('#333F4C')}
            //   borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex
                h="20"
                alignItems="center"
                mx="40px"
                mt="10px"
                mb={10}
                justifyContent="space-between"
            >
                <Logo />
                <CloseButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onClose}
                    color="white"
                />
            </Flex>

            {LinkItems.map(link =>
                link.adminOnly && !selector.isAdmin ? null : (
                    <NavItem key={link.name} link={link.link} icon={link.icon}>
                        {link.name}{' '}
                        {link.adminOnly && (
                            <Badge
                                variant={'outline'}
                                ml={1}
                                colorScheme={'whiteAlpha'}
                            >
                                ADMIN
                            </Badge>
                        )}
                    </NavItem>
                )
            )}
        </Box>
    );
};

const NavItem = ({ icon, link, children, ...rest }) => {
    return (
        <NavLink
            to={link}
            style={{
                textDecoration: 'none',
                color: 'white',
            }}
            activeStyle={{
                color: '#FAE100',
            }}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'rgba(0,0,0,0.2)',
                }}
                {...rest}
            >
                {icon && (
                    <Icon mr="4" fontSize="16" color="#FAE100" as={icon} />
                )}
                {children}
            </Flex>
        </NavLink>
    );
};

const MobileNav = ({ onOpen, ...rest }) => {
    const [showSignOutConfirm, setShowSignOutConfirm] = useState(false);
    const history = useHistory();
    const signOutConfirm = () => {
        setShowSignOutConfirm(!showSignOutConfirm);
    };
    const selector = useSelector(state => state.Login.details);
    const { title } = usePageContext();

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 5, md: '48px' }}
            height={'100px'}
            alignItems="center"
            bg={{
                md: useColorModeValue('#F8F8F8'),
                base: useColorModeValue('#333F4C'),
            }}
            justifyContent={{ base: 'space-between' }}
            {...rest}
        >
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu color="white" />}
            />

            <Text
                display={{ base: 'none', md: 'flex' }}
                flexGrow={1}
                color={'#00B2B1'}
                fontSize={'1.6em'}
                fontWeight={600}
            >
                {title}
            </Text>

            <Text display={{ base: 'flex', md: 'none' }}>
                <Logo />
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <HStack>
                                <Avatar size={'sm'} src={selector.img} />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    <Text fontSize="md">{selector.name}</Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue(
                                'gray.200',
                                'gray.700'
                            )}
                        >
                            {/* <MenuItem
                                onClick={() => history.push('/edit_profile')}
                            >
                                Profile
                            </MenuItem> */}
                            <MenuItem
                                onClick={() => history.push('/change_password')}
                            >
                                Change password
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={signOutConfirm}>
                                Sign out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
            {/* confirm sign out */}

            <Alert
                alertOpen={showSignOutConfirm}
                onclose={signOutConfirm}
                action={
                    <Button
                        colorScheme={'gray'}
                        onClick={() => history.push('/logout')}
                    >
                        Log out
                    </Button>
                }
                title="Log out?"
                body="Are you sure to logout?"
                isCentered
            />
        </Flex>
    );
};
