import {createStore , combineReducers} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Login  from './reducer'
const persistConfig = {
  key: 'tech.yarti.influencer',
  storage
}
let reducers = combineReducers({
  Login,
})
const persistedReducer = persistReducer(persistConfig, reducers)
export const store = createStore(persistedReducer)
export const persistor = persistStore(store)