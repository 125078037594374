import { lazy, Suspense, useEffect } from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    useParams,
} from 'react-router-dom';
import { store, persistor } from './Redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useTranslation } from 'react-i18next';
import Sidebar from './components/Sidebar';
import { ThemeProvider } from 'styled-components';
import { StyledTheme } from './theme';
import { PageContextProvider } from './context/pageContext';
import GoToLink from './pages/GoToLink';
const PrivateRoute = ({ component, path }) => {
    return store.getState().Login.isLogged ? (
        <Sidebar>
            {' '}
            <Route exact path={path} component={component} />
        </Sidebar>
    ) : (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: path },
            }}
        />
    );
};

// routes import
const Index = lazy(() => import('./pages/Index'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const PublicProfile = lazy(() => import('./pages/PublicProfile'));

// private imports starts
const Logout = lazy(() => import('./pages/Logout'));

const Home = lazy(() => import('./pages/Home/Home'));
const Subscription = lazy(() => import('./pages/Home/Subscription'));
const AffiliateLinks = lazy(() => import('./pages/Home/AffiliateLinks'));
const CreateLink = lazy(() => import('./pages/Home/CreateLink'));
const EditLink = lazy(() => import('./pages/Home/EditLink'));

const Profile = lazy(() => import('./pages/Home/Profile'));
const EditProfile = lazy(() => import('./pages/Home/EditProfile'));
const ChangePassword = lazy(() => import('./pages/Home/ChangePassword'));

// admin
const AdminUsers = lazy(() => import('./pages/Admin/Users'));
const AdminTransactions = lazy(() => import('./pages/Admin/Transactions'));

// private imports ends

const App = () => {
    const { i18n } = useTranslation();
    useEffect(() => {
        let lang = localStorage.getItem('language');
        if (lang === 'en') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('sp');
        }
    }, [i18n]);
    return (
        <ThemeProvider theme={StyledTheme}>
            <PageContextProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        {/* <ColorModeSwitcher /> */}
                        <Suspense fallback={<>Loading ...</>}>
                            <BrowserRouter>
                                <Switch>
                                    <Route exact path="/" component={Index} />
                                    {/* <Route exact path="/about" component={About} /> */}
                                    {/* <Route exact path="/pricing" component={Pricing} /> */}
                                    <Route
                                        exact
                                        path="/login"
                                        component={Login}
                                    />
                                    <Route
                                        exact
                                        path="/register"
                                        component={Register}
                                    />
                                    <Route
                                        exact
                                        path="/verify/email"
                                        component={VerifyEmail}
                                    />
                                    <Route
                                        exact
                                        path="/forgot"
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        exact
                                        path="/reset"
                                        component={ResetPassword}
                                    />

                                    <Route
                                        exact
                                        path="/profile/:username"
                                        component={PublicProfileRedirect}
                                    />
                                    <Route
                                        exact
                                        path="/goto/:id"
                                        component={GoToLink}
                                    />

                                    {/* private route starts */}
                                    <PrivateRoute
                                        exact
                                        path="/logout"
                                        component={Logout}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/home"
                                        component={Home}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/subscription"
                                        component={Subscription}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/links"
                                        component={AffiliateLinks}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/create_link"
                                        component={CreateLink}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/edit/:id"
                                        component={EditLink}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/profile"
                                        component={Profile}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/edit_profile"
                                        component={EditProfile}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/change_password"
                                        component={ChangePassword}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/admin/users"
                                        component={AdminUsers}
                                    />
                                    <PrivateRoute
                                        exact
                                        path="/admin/transaction"
                                        component={AdminTransactions}
                                    />

                                    <Route
                                        exact
                                        path="/:username"
                                        component={PublicProfile}
                                    />

                                    {/* private roue ends */}
                                </Switch>
                            </BrowserRouter>
                        </Suspense>
                    </PersistGate>
                </Provider>
            </PageContextProvider>
        </ThemeProvider>
    );
};

export default App;

const PublicProfileRedirect = () => {
    const { username } = useParams();
    return <Redirect to={'/' + username} />;
};
