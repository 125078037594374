const home = {
  logout : "Logout",
  areYouSuretoLogout : "Are you sure to logout?",
  cancel  : "Cancel",
  loggedOut : "Logged out successfully!",
};
// export default home;
module.exports = home


