import { Image, useColorMode } from '@chakra-ui/react';
import { useMemo } from 'react';
import logodark from '../assets/images/logo.png';

const Logo = ({ color }) => {
    const { colorMode } = useColorMode();
    const images = useMemo(() => {
        return {
            dark: logodark,
            light: logodark,
        };
    }, []);
    return (
        <Image
            width="180px"
            objectFit="contain"
            alt="Influencer"
            src={images[colorMode]}
        />
    );
};
export default Logo;
