import { useEffect } from 'react';
import { Send } from '../helper';
import { useParams } from 'react-router-dom';
import { ErrorToast } from '../toast';

const GoToLink = () => {
    const { id } = useParams();
    useEffect(() => {
        const gotoAPI = async () => {
            let res = await Send('GET', '/public/goto/' + id);
            if (res.type === 'error') {
                ErrorToast(res.msg);
            } else {
                window.location.href = res.data.link;
            }
        }

        if (id) {
            gotoAPI();
        }
    }, [id]);
    return <div>Redirecting you ....</div>;
};
export default GoToLink;
