import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
const breakpoints = createBreakpoints({
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
});
export const theme = extendTheme(
    {
        breakpoints,
        colors: {
            purple: {
                100: '#3D74E5',
                200: '#3D74E5',
                300: '#3D74E5',
                400: '#3D74E5',
                500: '#3D74E5',
                600: '#3D74E5',
                700: '#3D74E5',
                800: '#3D74E5',
                900: '#3D74E5',
            },
            teal: {
                50: '#acffff',
                100: '#7dfffe',
                200: '#4dfffe',
                300: '#27fffe',
                400: '#17e6e4',
                500: '#00b3b1',
                600: '#00807f',
                700: '#004e4d',
                800: '#001c1b',
            },
            customGrey: '#F9F9F9',
        },
    },
    {
        fonts: {
            heading: 'Roboto',
            body: 'Roboto',
        },
    },
    {
        styles: {
            global: {
                a: {
                    color: '#0D4BA0',
                },
                // body : {
                //   bg : "#eee"
                // }
            },
        },
    },
    withDefaultColorScheme({
        colorScheme: 'teal',
    })
);

export const StyledTheme = {
    palette: {
        teal: '#00B2B1',
        tealDark: '#008080',
        tealDarker: '#016767',
        charcoal: '#333F4C',
        gray: '#A2AAB6',
        lightGray: '#D0D4E3',
        white: '#FFFFFF',
        yellow: '#FAE100',
    },
};

export type LinkdollyTheme = typeof StyledTheme;

export function color(color: keyof typeof StyledTheme.palette) {
    return (data: any) => data.theme.palette[color];
}
