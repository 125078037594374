import axios from 'axios';
import { store } from './Redux/store';
const isDev = process.env.NODE_ENV === 'development'; 
export const baseUrl = isDev
    ? 'http://localhost:5000'
    : 'https://api.linkdolly.com';
export const imageBaseUrl = 'https://randomdipesh.cachefly.net';
const apiURL = baseUrl + '/api';

export const Send = async (method, url, data) => {
    let lang = localStorage.getItem('language');
    if (lang === 'en') {
        lang = 'en';
    } else {
        lang = 'sl';
    }
    let response = await axios({
        url: apiURL + url,
        method,
        data,
        headers: {
            'Accept-Language': lang,
        },
    });
    response = response.data;
    return response;
};

export const SendSecure = async (method, url, data) => {
    let lang = localStorage.getItem('language');
    if (lang === 'en') {
        lang = 'en';
    } else {
        lang = 'sl';
    }
    const token = store.getState().Login.details.token;
    let response = await axios({
        url: apiURL + url,
        method,
        headers: {
            authorization: 'Bearer ' + token,
            'Accept-Language': lang,
        },
        data,
    });
    response = response.data;
    if (response.type === 'logout') {
        window.location.href = '/logout';
    } else if (response.type === 'limited') {
        window.location.href = '/limited';
    } else if (response.type === 'requires_membership') {
        window.location.href = '/subscription';
    } else {
        return response;
    }
};

export const useQueryString = search => {
    const params = new URLSearchParams(search);
    return params;
};

export const getUniqueItemsWithSpecialCharFilter = arr => {
    const uniqueMap = new Map();
    arr.forEach(item => {
        const cleanedItem = item
            ?.replace(/[^\w\s]/gi, '')
            ?.trim()
            ?.toLowerCase();
        if (!uniqueMap.has(cleanedItem)) {
            uniqueMap.set(cleanedItem, item);
        }
    });

    return Array.from(uniqueMap.values());
};

export const replaceSpaceWithPlus = str => {
    return str?.replaceAll(' ', '+');
};

export const getCleanItem = item => {
    const cleanedItem = item
        ?.replace(/[^\w\s]/gi, '')
        ?.trim()
        ?.toLowerCase();
    return cleanedItem;
};
