import { AlertDialog, AlertDialogContent, AlertDialogOverlay,AlertDialogHeader,AlertDialogFooter,AlertDialogBody,Button } from "@chakra-ui/react";
import home from "../constants/home";
import {useTranslation} from 'react-i18next'

const Alert = ({alertOpen,onclose,action,title,body,isCentered}) => {
  const {t}  = useTranslation()
  return (
    <AlertDialog
      isOpen={alertOpen}
      onClose={onclose}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay >
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme = "red" mr = {3} onClick={onclose}>{t(home.cancel)}</Button> 
            
            {action}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
export default Alert;
