import { LOG_IN, LOG_OUT, CHANGE_PIC, CHANGE_NAME } from './constant';
const initialState = {
    isLogged: false,
    details: {},
};

const Login = (state = initialState, action) => {
    switch (action.type) {
        case LOG_IN: {
            return { ...state, isLogged: true, details: action.payload };
        }
        case CHANGE_PIC: {
            return {
                ...state,
                details: { ...state.details, img: action.payload },
            };
        }
        case CHANGE_NAME: {
            return {
                ...state,
                details: { ...state.details, name: action.payload.name },
            };
        }
        case LOG_OUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export default Login;
