import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from './theme';
const toast = createStandaloneToast({theme : theme});
const showToast = (title, text, type) => {
  if(!toast.isActive(title)){
    toast({
        id: title,
        title,
        description: text,
        status: type,
        duration: 3000,
        isClosable: true,
        position: 'top-right',
    });
  }
 
};
export const SuccessToast = (text) => {
  showToast("Success!", text, 'success');
};

export const ErrorToast = (text) => {
  showToast("Error!", text, 'error');
};
