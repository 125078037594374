import React from 'react';

const PageContext = React.createContext({
    title: '',
    setTitle: title => {},
});

export function PageContextProvider({ children }) {
    const [title, setTitle] = React.useState('');

    return (
        <PageContext.Provider value={{ title, setTitle }}>
            {children}
        </PageContext.Provider>
    );
}

export const usePageContext = () => React.useContext(PageContext);
